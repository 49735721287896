export const host = window.location.protocol + '//' + window.location.host + '/api';

export const routingApi = {
    api_default: '/',

    // Authentication
    api_login: '/login',
    api_pre_login: '/pre-login',
    api_logout: '/logout',
    api_create_account: '/create-account', // POST
    api_create_account_token_confirmation: '/create-account/token-confirmation/{token}', // GET
    api_forgot_password: '/forgot-password',

    // Free Tools
    api_free_tools_secret: '/free-tools/secret/{secret}', // GET & POST
    api_free_tools_secret_reveal: '/free-tools/secret/{secret}/reveal', // POST

    // Team
    api_team_collaboratives: '/team/collaboratives', // GET & POST
    api_team_collaborative_accept: '/team/collaborative/{team}/accept', // GET
    api_team_collaborative_leave: '/team/collaborative/{team}/leave', // GET
    api_team_owner: '/team/owner', // GET & POST
    api_team_owner_add_user: '/team/owner/add-user', // POST
    api_team_owner_remove_user: '/team/owner/remove-user', // POST
    api_team: '/team/{team}', // GET & POST





    // My Profile
    api_my_profile: '/my-profile', // GET & POST
    api_my_profile_password: '/my-profile/password', // GET
    api_my_profile_avatar: '/my-profile/avatar/{avatar}', // GET
    api_my_profile_avatar_upload: '/my-profile/avatar/upload', // GET

    // My Profile
    api_enterprise: '/enterprise/{enterprise}', // GET & POST
    api_enterprise_logo: '/enterprise/{enterprise}/logo', // GET

    // Administrator
    api_administrator_softwares: '/administrator/softwares', // GET
    api_administrator_software: '/administrator/software/{software}', // GET & POST
    api_administrator_software_status: '/administrator/software/{software}/status', // GET
    api_administrator_software_image: '/administrator/software/{software}/image/{image}', // GET
    api_administrator_software_image_upload: '/administrator/software/{software}/image/upload', // POST
    api_administrator_software_enum_enterprises: '/administrator/software/enum/enterprises', // GET
    api_administrator_software_enum_enterprise_subscriptions: '/administrator/software/enum/enterprise/{enterprise}/subscriptions', // GET
    api_administrator_software_enum_servers: '/administrator/software/enum/servers', // GET

    api_administrator_backup_database: '/administrator/software/{software}/backup-database', // GET & POST
    api_administrator_backup_database_restore: '/administrator/software/{software}/backup-database/{backupDatabase}/restore', // GET
    api_administrator_backup_database_download: '/administrator/software/{software}/backup-database/{backupDatabase}/download', // GET
    api_administrator_backup_databases: '/administrator/software/{software}/backup-databases', // GET
    api_administrator_backup_database_run: '/administrator/software/{software}/backup-database/run', // GET

    api_administrator_backup_volume: '/administrator/software/{software}/backup-volume', // GET & POST
    api_administrator_backup_volume_restore: '/administrator/software/{software}/backup-volume/{backupVolume}/restore', // GET
    api_administrator_backup_volume_download: '/administrator/software/{software}/backup-volume/{backupVolume}/download', // GET
    api_administrator_backup_volumes: '/administrator/software/{software}/backup-volumes', // GET
    api_administrator_backup_volume_run: '/administrator/software/{software}/backup-volume/run', // GET

    api_administrator_servers: '/administrator/servers', // GET
    api_administrator_servers_logo: '/administrator/servers/logo/{logo}', // GET

    // Onglet General
    api_administrator_server: '/administrator/server/{server}', // GET & POST
    api_administrator_server_information: '/administrator/server/{server}/information', // GET
    api_administrator_server_directory_system: '/administrator/server/{server}/directory-system', // GET & POST

    api_administrator_server_extension_upgrade: '/administrator/server/{server}/extension/upgrade', // GET & POST
    api_administrator_server_extension_update: '/administrator/server/{server}/extension/update', // GET & POST
    api_administrator_server_ssh: '/administrator/server/{server}/ssh', // POST
    api_administrator_server_antimalware_info: '/administrator/server/{server}/antimalware/info', // POST
    api_administrator_server_antimalware_enable: '/administrator/server/{server}/antimalware/enable', // POST
    api_administrator_server_antimalware_disable: '/administrator/server/{server}/antimalware/disable', // POST
    api_administrator_server_enum_enterprises: '/administrator/server/enum/enterprises', // GET
    api_administrator_server_log_cpu: '/administrator/server/{server}/log/cpu', // GET
    api_administrator_server_log_ram: '/administrator/server/{server}/log/ram', // GET
    api_administrator_server_log_disk_write_read: '/administrator/server/{server}/log/disk-write-read', // GET

    api_administrator_server_firewall: '/administrator/server/{server}/firewall', // GET
    api_administrator_server_firewall_pfsense: '/administrator/server/{server}/firewall/pfsense', // POST
    api_administrator_server_firewall_iptables: '/administrator/server/{server}/firewall/iptables', // GET & POST

    api_administrator_server_reverse_proxy: '/administrator/server/{server}/reverse-proxy', // GET & POST
    api_administrator_server_reverse_proxy_reconfigure: '/administrator/server/{server}/reverse-proxy/reconfigure', // GET

    api_administrator_users: '/administrator/users', // GET
    api_administrator_user: '/administrator/user/{user}', // GET & POST
    api_administrator_user_avatar: '/administrator/user/{user}/avatar/{avatar}', // GET
    api_administrator_user_upload_avatar: '/administrator/user/{user}/avatar/upload', // POST

    api_administrator_enterprises: '/administrator/enterprises', // GET
    api_administrator_enterprise: '/administrator/enterprise/{enterprise}', // GET & POST
    api_administrator_enterprise_logo: '/administrator/enterprise/{enterprise}/logo/{logo}', // GET
    api_administrator_enterprise_logo_upload: '/administrator/enterprise/{enterprise}/logo/upload', // POST
    api_administrator_enterprise_owners: '/administrator/enterprise/{enterprise}/owners', // GET
    api_administrator_enterprise_owners_autocomplete: '/administrator/enterprise/{enterprise}/owner/autocomplete', // GET
    api_administrator_enterprise_owner: '/administrator/enterprise/{enterprise}/owner/{owner}', // POST & DELETE

    api_administrator_subscriptions: '/administrator/subscriptions', // GET
    api_administrator_subscriptions_image: '/administrator/subscriptions/image/{image}', // GET
    api_administrator_subscription: '/administrator/subscription/{subscription}', // GET & POST
    api_administrator_subscription_enum_enterprises: '/administrator/subscription/enum/enterprises', // GET
    api_administrator_subscription_enum_subscription_types: '/administrator/subscription/enum/subscription-types', // GET
};

export const cacheConfig = {};

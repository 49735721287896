// Layouts
import AuthenticationLayout from "src/Layout/AuthenticationLayout/AuthenticationLayout";
import MainLayout from "src/Layout/MainLayout/MainLayout";

// Views
import Login from "src/View/Login/Login";
import CreateAccount from "../View/CreateAccount/CreateAccount";
import ForgotPassword from "src/View/ForgotPassword/ForgotPassword";
import Dashboard from "src/View/Dashboard/Dashboard";
import Vpn from "../View/Vpn/Vpn";
import Softwares from "../View/Softwares/Softwares";
import Support from "../View/Support/Support";
import MyProfile from "../View/MyProfile/MyProfile";
import Enterprise from "../View/Enterprise/Enterprise";
import AdministratorUsers from "src/View/Administrator/Users/Users";
import AdministratorUser from "src/View/Administrator/User/User";
import AdministratorSoftwares from "../View/Administrator/Softwares/Softwares";
import AdministratorSoftware from "../View/Administrator/Software/Software";
import AdministratorEnterprises from "../View/Administrator/Enterprises/Enterprises";
import AdministratorEnterprise from "../View/Administrator/Enterprise/Enterprise";
import AdministratorServers from "../View/Administrator/Servers/Servers";
import AdministratorServer from "../View/Administrator/Server/Server";
import AdministratorSubscriptions from "../View/Administrator/Subscriptions/Subscriptions";
import AdministratorSubscription from "../View/Administrator/Subscription/Subscription";
import Secret from "../View/Secret/Secret";
import Teams from "../View/Teams/Teams";

export const routing = {
    // Authentication
    login: {path: '/login', view: Login, layout: AuthenticationLayout, roles: []},
    createAccount: {path: '/create-account', view: CreateAccount, layout: AuthenticationLayout, roles: []},
    forgotPassword: {path: '/forgot-password', view: ForgotPassword, layout: AuthenticationLayout, roles: []},

    // Secret
    free_tools_secret: {path: '/free-tools/secret/:secretParams?', view: Secret, layout: AuthenticationLayout, roles: []},



    // Dashboard
    dashboard: {path: '/dashboard', view: Dashboard, layout: MainLayout, roles: ['ROLE_USER']},

    // Servers
    bareMetal: {path: '/bare-metal', view: Softwares, layout: MainLayout, roles: ['ROLE_USER']},

    // Softwares
    softwares: {path: '/softwares', view: Softwares, layout: MainLayout, roles: ['ROLE_USER']},

    // DNS
    dns: {path: '/dns', view: Dashboard, layout: MainLayout, roles: ['ROLE_USER']},

    // VPN
    vpn: {path: '/vpn', view: Vpn, layout: MainLayout, roles: ['ROLE_USER']},

    // mailbox
    mailbox: {path: '/mailbox', view: Dashboard, layout: MainLayout, roles: ['ROLE_USER']},

    // Passwords
    passwords: {path: '/passwords', view: Dashboard, layout: MainLayout, roles: ['ROLE_USER']},

    // secrets
    secrets: {path: '/secrets', view: Dashboard, layout: MainLayout, roles: ['ROLE_USER']},

    // secrets
    fileTransfer: {path: '/file-transfer', view: Dashboard, layout: MainLayout, roles: ['ROLE_USER']},

    // teams
    teams: {path: '/teams', view: Teams, layout: MainLayout, roles: ['ROLE_USER']},

    // Support
    support: {path: '/support', view: Support, layout: MainLayout, roles: ['ROLE_USER']},

    // Support
    documents: {path: '/documents', view: Support, layout: MainLayout, roles: ['ROLE_USER']},



    // Profile
    my_profile: {path: '/my-profile', view: MyProfile, layout: MainLayout, roles: ['ROLE_USER']},

    // Enterprise
    enterprise: {path: '/enterprise/:enterprise?', match: /\/enterprise(\/.{36})?$/, view: Enterprise, layout: MainLayout, roles: ['ROLE_USER']},

    // Administrator
    administrator_users: {path: '/administrator/users', menuKeys: ['administrator_user'], view: AdministratorUsers, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_USER']},
    administrator_user: {path: '/administrator/user/:user?', match: /\/administrator\/user(\/.{36})?$/, view: AdministratorUser, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_USER']},

    administrator_enterprises: {path: '/administrator/enterprises', menuKeys: ['administrator_enterprise'], view: AdministratorEnterprises, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_ENTERPRISE']},
    administrator_enterprise: {path: '/administrator/enterprise/:enterprise?', match: /\/administrator\/enterprise(\/.{36})?$/, view: AdministratorEnterprise, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_ENTERPRISE']},

    administrator_servers: {path: '/administrator/servers', menuKeys: ['administrator_server'], view: AdministratorServers, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_SERVER']},
    administrator_server: {path: '/administrator/server/:serverParams?', match: /\/administrator\/server(\/.{36})?$/, view: AdministratorServer, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_SERVER']},

    administrator_subscriptions: {path: '/administrator/subscriptions', menuKeys: ['administrator_subscription'], view: AdministratorSubscriptions, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_SUBSCRIPTION']},
    administrator_subscription: {path: '/administrator/subscription/:subscription?', match: /\/administrator\/subscription(\/.{36})?$/, view: AdministratorSubscription, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_SUBSCRIPTION']},

    administrator_softwares: {path: '/administrator/softwares', menuKeys: ['administrator_software'], view: AdministratorSoftwares, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_SOFTWARE']},
    administrator_software: {path: '/administrator/software/:software?', match: /\/administrator\/software(\/.{36})?$/, view: AdministratorSoftware, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR_SOFTWARE']},
};

import React from 'react';
import {Link} from "react-router-dom";
import {Box} from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import {styled, useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import TextFieldComponent from "src/Component/TextFieldComponent";
import ButtonComponent from "src/Component/ButtonComponent";
import FooterIllustration from "./Component/FooterIllustration";
import {Alert, Fade, Grid} from "@mui/material";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import {formHandlerInit} from "src/Handler/FormHandler";
import {color} from "src/Config/Theme";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import {ArrowBackIos} from "@mui/icons-material";

// ** Styled Components
const LoginIllustration = styled('img')(({theme}) => ({
    zIndex: 2,
    maxHeight: 620,
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down(1540)]: {
        maxHeight: 550
    },
    [theme.breakpoints.down('lg')]: {
        maxHeight: 500
    }
}))
const RightWrapper = styled(Box)(({theme}) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
        maxWidth: 450
    },
    [theme.breakpoints.up('lg')]: {
        maxWidth: 600
    },
    [theme.breakpoints.up('xl')]: {
        maxWidth: 750
    }
}));
const LinkStyled = styled(Link)(() => ({
    fontSize: '13px',
    textDecoration: 'none',
    color: color.primary
}));

function CreateAccount() {
    const theme = useTheme();
    const hidden = useMediaQuery(theme.breakpoints.down('md'));
    const [isError, setIsError] = React.useState(false);
    const [isSend, setIsSend] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [randIllustration] = React.useState(Math.random() < 0.5);

    // Form
    const [form, setForm] = React.useState({
        email: {
            id: 'email',
            label: 'Email',
            helperText: 'Entrez votre email.',
            type: 'text',
            value: '',
            options: {validation: ['required', 'email']}
        },
        password: {
            id: 'password',
            label: 'Mot de passe',
            helperText: 'Entrez votre mot de passe.',
            type: 'password',
            value: '',
            options: {validation: ['required']}
        },
        lastname: {
            id: 'lastname',
            label: 'Nom',
            helperText: 'Entrez votre nom.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        },
        firstname: {
            id: 'firstname',
            label: 'Prénom',
            helperText: 'Entrez votre prénom.',
            type: 'text',
            value: '',
            options: {validation: ['required']}
        }
    });
    const handler = formHandlerInit(form, setForm);

    const loginAPI = () => {
        return;
        if (handler.checkValid() < 1) {
            handler.setLoading(true);
            setIsError(false);
            setLoading(true);

            routingApiHandlerFetch({
                route: 'api_create_account',
                method: 'POST',
                data: {
                    email: form.email.value,
                    password: form.password.value,
                    lastname: form.lastname.value,
                    firstname: form.firstname.value,
                },
                success: () => {
                    setIsSend(true);
                    handler.setLoading(false);
                    setLoading(false);
                },
                error: (response) => {
                    if (response.code === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        setIsError(true);
                    }

                    handler.setLoading(false);
                    setLoading(false);
                }
            });
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            minHeight: '100vh',
            overflowX: 'hidden',
            position: 'relative'
        }}>
            {!hidden ? (
                <Fade in={true} {...{timeout: 500}}>
                    <Box sx={{
                        flex: 1,
                        display: 'flex',
                        position: 'relative',
                        alignItems: 'center',
                        borderRadius: '20px',
                        justifyContent: 'center',
                        backgroundColor: color.backgroundSecondary,
                        margin: '2rem 0rem 2rem 2rem'
                    }}>
                        <LoginIllustration alt='login-illustration' src={'/images/' + (randIllustration ? 'illustration-boy-login-dark.webp' : 'illustration-girl-login-dark.webp')}/>
                        <FooterIllustration/>
                    </Box>
                </Fade>
            ) : null}
            <Fade in={true} {...{timeout: 500}}>
                <RightWrapper>
                    <Box
                        sx={{
                            padding: '0 25px',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Box sx={{width: '100%', maxWidth: 400}}>

                            <LinkStyled to={'https://maker-system.com/'}>
                                <img width={34} height={34} src={'/images/logo.webp'} alt={'logo'}/>
                            </LinkStyled>

                            <Box sx={{margin: '15px 0'}}>
                                <Typography sx={{mb: 1.5, fontWeight: 500, fontSize: '1.625rem', lineHeight: 1.385, color: color.textPrimary}}>
                                    {`Rejoignez-nous dès maintenant !`}
                                </Typography>
                                <Typography sx={{color: color.textPrimary, fontWeight: '100 !important'}}>
                                    L’innovation et la performance à portée de main. 🚀<br/>
                                </Typography>
                            </Box>

                            {isSend ? <Fade in={true} {...{timeout: 500}}>
                                <Box sx={{textAlign: 'center', width: '100%', marginTop: '25px'}}>
                                    <Typography sx={{fontSize: '15px', color: color.textPrimary}}>
                                        Un email de confirmation a été envoyé !
                                    </Typography> <br/>
                                    <img src="/images/mail2.png" alt="mail" style={{width: '45%', margin: 'auto'}}/> <br/><br/>
                                    <Typography sx={{fontSize: '15px', color: color.textPrimary}}>
                                        Veuillez vérifier votre boîte de réception et cliquer sur le lien de validation pour activer votre compte.
                                        Une fois confirmé, vous pourrez profiter pleinement de toutes les fonctionnalités de Maker System.
                                    </Typography>

                                    <Box sx={{textAlign: 'center', width: '100%', marginTop: '25px'}}>
                                        <LinkStyled to={routingHandlerGetRoutePathname('login')} sx={{fontSize: '15px'}}>
                                            <ArrowBackIos sx={{fontSize: 17, color: color.primary, verticalAlign: 'bottom'}}/> Retour à la connexion
                                        </LinkStyled>
                                    </Box>
                                </Box>
                            </Fade> : <Fade in={true} {...{timeout: 500}}>
                                <Box>
                                    {isError && <><Alert sx={{opacity: 0.7}} severity="error">Oups ! Une erreur inattendue s'est produite.</Alert><br/></>}
                                    <form noValidate autoComplete='current-password'>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <TextFieldComponent handler={handler} id={'email'} onSubmit={loginAPI}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextFieldComponent handler={handler} id={'lastname'} onSubmit={loginAPI}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextFieldComponent handler={handler} id={'firstname'} onSubmit={loginAPI}/>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <TextFieldComponent handler={handler} id={'password'} onSubmit={loginAPI}/>
                                            </Grid>
                                        </Grid>

                                        <Box sx={{width: '100%', marginTop: '25px'}}>
                                            <ButtonComponent label={'La création de compte est désactivée temporairement'} onClick={loginAPI} loading={loading} disabled={true}/>
                                        </Box>
                                    </form>

                                    <Box sx={{textAlign: 'center', width: '100%', marginTop: '25px'}}>
                                        <LinkStyled to={routingHandlerGetRoutePathname('login')} sx={{fontSize: '15px'}}>
                                            <ArrowBackIos sx={{fontSize: 17, color: color.primary, verticalAlign: 'bottom'}}/> Retour à la connexion
                                        </LinkStyled>
                                    </Box>
                                </Box>
                            </Fade>}
                        </Box>
                    </Box>
                </RightWrapper>
            </Fade>
        </Box>
    );
}

export default CreateAccount;
// C'est parti !
import React from 'react';
import {Link, useSearchParams} from "react-router-dom";
import {Box} from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import {styled, useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import TextFieldComponent from "src/Component/TextFieldComponent";
import ButtonComponent from "src/Component/ButtonComponent";
import FooterIllustration from "./Component/FooterIllustration";
import {Alert, Fade} from "@mui/material";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import {formHandlerInit} from "src/Handler/FormHandler";
import {color} from "src/Config/Theme";
import {useDispatch} from "react-redux";
import {authenticationReduxLogin} from "src/Redux/authenticationRedux";
import PinField from "react-pin-field";

// ** Styled Components
const LoginIllustration = styled('img')(({theme}) => ({
    zIndex: 2,
    maxHeight: 620,
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down(1540)]: {
        maxHeight: 550
    },
    [theme.breakpoints.down('lg')]: {
        maxHeight: 500
    }
}))
const RightWrapper = styled(Box)(({theme}) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
        maxWidth: 450
    },
    [theme.breakpoints.up('lg')]: {
        maxWidth: 600
    },
    [theme.breakpoints.up('xl')]: {
        maxWidth: 750
    }
}));
const LinkStyled = styled(Link)(() => ({
    fontSize: '13px',
    textDecoration: 'none',
    color: color.primary
}));

function Login() {
    const [searchParams] = useSearchParams();
    const tc = searchParams.get("tc");

    const theme = useTheme();
    const dispatch = useDispatch();
    const hidden = useMediaQuery(theme.breakpoints.down('md'));
    const [isError, setIsError] = React.useState(false);
    const [isConfirmation, setIsConfirmation] = React.useState(false);
    const [isPreLogin, setIsPreLogin] = React.useState(true);
    const [code, setCode] = React.useState('');
    const [hasErrorCode, setHasErrorCode] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [randIllustration] = React.useState(Math.random() < 0.5);
    const handleComplete = (value) => {
        console.log(value);
        setCode(value);
    };
    // Form
    const [form, setForm] = React.useState({
        email: {
            id: 'email',
            label: 'Email',
            helperText: 'Entrez votre email.',
            type: 'text',
            value: '',
            options: {validation: ['required', 'email']}
        },
        password: {
            id: 'password',
            label: 'Mot de passe',
            helperText: 'Entrez votre mot de passe.',
            type: 'password',
            value: '',
            options: {validation: ['required']}
        }
    });
    const handler = formHandlerInit(form, setForm);

    const loginAPI = () => {
        if (handler.checkValid() < 1) {
            handler.setLoading(true);
            setLoading(true);
            setIsError(false);
            setIsConfirmation(false);
            setHasErrorCode(false);

            if (isPreLogin) {
                routingApiHandlerFetch({
                    route: 'api_pre_login',
                    method: 'POST',
                    data: {
                        email: form.email.value,
                        password: form.password.value,
                    },
                    success: (response) => {
                        setIsPreLogin(false);
                        setLoading(false);
                    },
                    error: (response) => {
                        if (response.code === 400) {
                            handler.setErrorApi(response.error);
                        } else {
                            setIsError(true);
                        }

                        handler.setLoading(false);
                        setLoading(false);
                    }
                });
            } else {
                routingApiHandlerFetch({
                    route: 'api_login',
                    method: 'POST',
                    data: {
                        email: form.email.value,
                        password: form.password.value,
                        code: code,
                    },
                    success: (response) => {
                        dispatch(authenticationReduxLogin(response.data));
                        handler.setLoading(false);
                        setLoading(false);
                    },
                    error: (response) => {
                        setHasErrorCode(true);
                        setLoading(false);
                    }
                });
            }
        }
    };

    React.useEffect(() => {
        if (tc) {
            routingApiHandlerFetch({
                route: 'api_create_account_token_confirmation',
                method: 'GET',
                params: {token: tc},
                success: (response) => {
                    if (response.data.isConfirmation) {
                        setIsConfirmation(true);
                    }
                },
                error: (response) => {
                }
            });
        }
    }, []);
    React.useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === "Enter" && code.length === 6) {
                loginAPI();
            }
        };

        document.addEventListener("keydown", handleKeyPress);
        return () => document.removeEventListener("keydown", handleKeyPress);
    }, [code]);

    return (
        <Box sx={{
            display: 'flex',
            minHeight: '100vh',
            overflowX: 'hidden',
            position: 'relative'
        }}>
            {!hidden ? (
                <Fade in={true} {...{timeout: 500}}>
                    <Box sx={{
                        flex: 1,
                        display: 'flex',
                        position: 'relative',
                        alignItems: 'center',
                        borderRadius: '20px',
                        justifyContent: 'center',
                        backgroundColor: color.backgroundSecondary,
                        margin: '2rem 0rem 2rem 2rem'
                    }}>
                        <LoginIllustration alt='login-illustration' src={'/images/' + (randIllustration ? 'illustration-boy-login-dark.webp' : 'illustration-girl-login-dark.webp')}/>
                        <FooterIllustration/>
                    </Box>
                </Fade>
            ) : null}
            <Fade in={true} {...{timeout: 500}}>
                <RightWrapper>
                    <Box
                        sx={{
                            padding: '0 25px',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Box sx={{width: '100%', maxWidth: 400}}>

                            <LinkStyled to={'https://maker-system.com/'}>
                                <img width={34} height={34} src={'/images/logo.webp'} alt={'logo'}/>
                            </LinkStyled>

                            <Box sx={{margin: '15px 0'}}>
                                <Typography sx={{mb: 1.5, fontWeight: 500, fontSize: '1.625rem', lineHeight: 1.385, color: color.textPrimary}}>
                                    {`Bienvenue sur la plateforme Maker System! 👋🏻`}
                                </Typography>
                                {isPreLogin ? <Typography sx={{color: color.textPrimary, fontWeight: '100 !important'}}>
                                        Veuillez vous connecter à votre compte
                                    </Typography> :
                                    <Typography sx={{color: color.textPrimary, fontWeight: '100 !important', fontSize: '14px'}}>
                                        Vérification en deux étapes 2FA 🔐<br/><br/>
                                        Nous avons envoyé un code de confirmation à 6 chiffres à votre adresse e-mail.
                                        Veuillez entrer ce code ci-dessous pour finaliser votre connexion à Maker System.
                                    </Typography>}
                            </Box>

                            {isError && <><Alert sx={{opacity: 0.7}} severity="error">Oups ! Une erreur inattendue s'est produite.</Alert><br/></>}
                            {isConfirmation && <><Alert sx={{opacity: 0.7}} severity="success">Félicitations ! Votre compte est désormais actif. Connectez-vous dès maintenant pour découvrir toutes nos services.</Alert><br/></>}

                            <form noValidate autoComplete='current-password'>
                                {isPreLogin && <>
                                    <Box sx={{padding: '5px 0'}}>
                                        <TextFieldComponent handler={handler} id={'email'} onSubmit={loginAPI}/>
                                    </Box>
                                    <Box sx={{padding: '5px 0'}}>
                                        <TextFieldComponent handler={handler} id={'password'} onSubmit={loginAPI}/>
                                    </Box>
                                </>}

                                {!isPreLogin && <>
                                    <form >
                                        <div className="tfa-pin-container">
                                            <PinField
                                                length={6}
                                                inputMode="numeric"
                                                autoFocus
                                                className="tfa-pin-input"
                                                onComplete={handleComplete}
                                            />
                                        </div>
                                    </form>
                                    {hasErrorCode && <Typography sx={{fontWeight: '100 !important', fontSize: '12px', color: color.colorError, textAlign: 'end'}}>
                                        Le code de vérification est incorrect ou a expiré.
                                    </Typography>}
                                </>}

                                {isPreLogin ?
                                    <Box sx={{textAlign: 'end', width: '100%'}}>
                                        <LinkStyled to={routingHandlerGetRoutePathname('forgotPassword')}>Mot de passe oublié ?</LinkStyled>
                                    </Box> : <Box sx={{textAlign: 'end', width: '100%'}}>
                                        <LinkStyled onClick={() => {
                                            setIsPreLogin(true);
                                            handler.setLoading(false);
                                        }}>Annuler</LinkStyled>
                                    </Box>}

                                <Box sx={{width: '100%', marginTop: '25px'}}>
                                    <ButtonComponent label={'Se connecter'} onClick={loginAPI} loading={loading}/>
                                </Box>

                                {(isPreLogin && !loading) && <Box sx={{width: '100%', marginTop: '10px'}}>
                                    <Link to={routingHandlerGetRoutePathname('createAccount')}>
                                        <ButtonComponent label={'Créer mon compte'} loading={loading} color={color.secondary} colorHover={color.secondaryHover}/>
                                    </Link>
                                </Box>}
                            </form>
                        </Box>
                    </Box>
                </RightWrapper>
            </Fade>
        </Box>
    );
}

export default Login;

import {Dashboard, BrightnessAuto, VpnLock, Dvr, Support, Key, Dns, Psychology, TravelExplore, Markunread, Send, Groups, TextSnippet, DriveFileMove} from '@mui/icons-material';

export const menu = [
    {
        id: 'dashboard',
        label: 'Tableau de bord',
        icon: <Dashboard/>,
    },
    {
        id: 'bareMetal',
        label: 'Bare Metal & VPS',
        icon: <Dns/>,
    },
    {
        id: 'softwares',
        label: 'Sites & Applications',
        icon: <Dvr/>,
    },
    {
        id: 'dns',
        label: 'Noms de domaine',
        icon: <TravelExplore/>,
    },
    {
        id: 'vpn',
        label: 'Accès sécurisé (VPN)',
        icon: <VpnLock/>,
    },
    {
        id: 'mailbox',
        label: 'Serveur SMTP & Emails',
        icon: <Markunread/>,
    },
    {
        id: 'passwords',
        label: 'Gestion des mots de passe',
        icon: <Key/>,
    },
    {
        id: 'secrets',
        label: 'Messages privés sécurisés',
        icon: <Send/>,
    },
    {
        id: 'fileTransfer',
        label: 'Transfert de fichiers',
        icon: <DriveFileMove/>,
    },
    {
        id: 'support',
        label: 'Support & Assistance',
        icon: <Support/>,
    },
    {
        id: 'documents',
        label: 'Documents',
        icon: <TextSnippet/>,
    },
    {
        id: 'administrator',
        label: 'Administrateur',
        icon: <BrightnessAuto/>,
        children: [
            {id: 'administrator_users', label: 'Utilisateurs'},
            {id: 'administrator_enterprises', label: 'Entreprises'},
            {id: 'administrator_servers', label: 'Serveurs'},
            {id: 'administrator_softwares', label: 'Logiciels'},
            {id: 'administrator_subscriptions', label: 'Abonnements'},
        ]
    }
];




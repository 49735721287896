import {createSlice} from '@reduxjs/toolkit';

const authenticationRedux = createSlice({
    name: 'authenticationRedux',
    initialState: {
        uuid: null,
        email: null,
        roles: null,
        enterprises: null,
        firstname: null,
        lastname: null,
        avatar: null,
    },
    reducers: {
        authenticationReduxLogin: (state, action) => {
            state.uuid = action.payload.uuid;
            state.email = action.payload.email;
            state.roles = action.payload.roles;
            state.teams = action.payload.teams;
            state.teamSelected = action.payload.teamSelected;
            state.firstname = action.payload.firstname;
            state.lastname = action.payload.lastname;
            state.avatar = action.payload.avatar;
        },
        authenticationReduxLogout: (state) => {
            state.uuid = null;
            state.email = null;
            state.roles = null;
            state.teams = null;
            state.teamSelected = null;
            state.firstname = null;
            state.lastname = null;
            state.avatar = null;
        },
        authenticationReduxAvatar: (state, action) => {
            console.log(action)
            state.avatar = action.payload;
        },
    },
});

export const {authenticationReduxLogin, authenticationReduxLogout, authenticationReduxAvatar} = authenticationRedux.actions;
export default authenticationRedux.reducer;

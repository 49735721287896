import React from 'react';
import {Box} from "@mui/system";
import {useDispatch} from "react-redux";
import {barMainLayoutReduxChange} from "src/Redux/barMainLayoutRedux";
import ShadowBoxComponent from "src/Component/ShadowBoxComponent";
import Typography from "@mui/material/Typography";
import {color} from "src/Config/Theme";
import {Dialog, DialogActions, DialogContent, DialogTitle, Fade, Grid} from "@mui/material";
import {Groups, Groups3, Cancel, PersonRemove, Settings, TaskAlt} from "@mui/icons-material";
import TableComponent from "src/Component/TableComponent";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import ButtonIconComponent from "src/Component/ButtonIconComponent";
import ButtonComponent from "src/Component/ButtonComponent";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import Loading from "../Loading/Loading";
import {formHandlerInit} from "src/Handler/FormHandler";
import TextFieldComponent from "src/Component/TextFieldComponent";
import {snackbarReduxOpen} from "src/Redux/snackbarRedux";
import {tableReduxReload} from "src/Redux/tableRedux";
import Swal from "sweetalert2";

const useStyles = {
    avatar: {
        background: color.primaryOpacity,
        borderRadius: '50%',
        overflow: 'hidden',
        fontSize: '12px',
        width: '28px',
        height: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginTop: '2px',
        marginLeft: '25px',
    }
};

function Teams() {
    const theme = useTheme();
    const hidden = useMediaQuery(theme.breakpoints.down('lg'));
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(true);

    // Tables MyTeam
    const [openDialogMyTeam, setOpenDialogMyTeam] = React.useState(false);
    const [teamName, setTeamName] = React.useState('');
    const [loadingTeam, setLoadingTeam] = React.useState(false);
    const [formTeam, setFormTeam] = React.useState({
        name: {
            id: 'name',
            label: 'Nom de l\'équipe',
            helperText: 'Entrez le nom de votre équipe.',
            type: 'text', value: '',
            options: {validation: ['required'], max: 35}
        }
    });
    const handlerTeam = formHandlerInit(formTeam, setFormTeam);
    const saveTeam = () => {
        if (handlerTeam.checkValid() < 1) {
            handlerTeam.setLoading(true);
            setLoadingTeam(true);
            routingApiHandlerFetch({
                route: 'api_team_owner',
                method: 'POST',
                data: {name: handlerTeam.getValue('name')},
                success: (response) => {
                    setTimeout(() => {
                        handlerTeam.setLoading(false);
                        setTeamName(handlerTeam.getValue('name'));
                        setLoadingTeam(false);
                        setOpenDialogMyTeam(false)
                    }, 750);
                },
                error: (response) => {
                    if (response.code === 400) {
                        handlerTeam.setErrorApi(response.error);
                    } else {
                        dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                    }
                    handlerTeam.setLoading(false);
                    setLoadingTeam(false)
                }
            });
        }
    };
    const [openDialogAddUser, setOpenDialogAddUser] = React.useState(false);
    const [formAddUser, setFormAddUser] = React.useState({
        email: {
            id: 'email',
            label: 'Adresse email du membre',
            helperText: 'Entrez l\'adresse email du membre.',
            type: 'text',
            value: '',
            options: {validation: ['required', 'email'], max: 35}
        }
    });
    const handlerAddUser = formHandlerInit(formAddUser, setFormAddUser);
    const saveAddUser = () => {
        if (handlerAddUser.checkValid() < 1) {
            handlerAddUser.setLoading(true);
            setLoadingTeam(true);
            routingApiHandlerFetch({
                route: 'api_team_owner_add_user',
                method: 'POST',
                data: {email: handlerAddUser.getValue('email')},
                success: () => {
                    setTimeout(() => {
                        dispatch(tableReduxReload({id: 'api_team_owner'}));
                        handlerAddUser.setLoading(false);
                        setLoadingTeam(false);
                        setOpenDialogAddUser(false);
                        handlerAddUser.reset();
                    }, 750);
                },
                error: (response) => {
                    if (response.code === 400) {
                        handlerAddUser.setErrorApi(response.error);
                    } else {
                        dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                    }
                    handlerAddUser.setLoading(false);
                    setLoadingTeam(false)
                }
            });
        }
    };

    const columnsMyTeam = [
        {
            name: "avatar",
            label: " ",
            options: {filter: false, sort: false}
        },
        {
            name: "email",
            label: "Email",
            options: {filter: true, sort: true}
        },
        {
            name: "firstname",
            label: "Prénom",
            options: {filter: true, sort: true}
        },
        {
            name: "lastname",
            label: "Nom",
            options: {filter: true, sort: true}
        },
        {
            name: "status",
            label: "Statut",
            options: {filter: true, sort: true}
        },
        {
            name: "actions",
            label: "Actions",
            options: {filter: false, sort: false}
        }
    ];
    const getActionMyTeam = (row) => {
        return (
            <>
                {(row.isAccepted && !row.isOwner) && <ButtonIconComponent
                    background={color.colorDeleteBackground}
                    backgroundHover={color.colorDeleteBackground}
                    color={color.colorDelete}
                    icon={Cancel}
                    tooltip={'Supprimer de l\'équipe'}
                    onClick={() => {
                        Swal.fire({
                            title: 'Confirmation',
                            html: '<p>' +
                                'Êtes-vous certain de vouloir retirer <br/>' + row.email + ' de votre équipe ?' +
                                '</p>',
                            icon: 'warning',
                            showCancelButton: true,
                            color: color.textPrimary,
                            background: color.backgroundPrimary,
                            confirmButtonColor: color.colorConfirm,
                            cancelButtonColor: color.colorCancel,
                            confirmButtonText: 'Oui',
                            cancelButtonText: 'Non'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                routingApiHandlerFetch({
                                    route: 'api_team_owner_remove_user',
                                    method: 'POST',
                                    data: {uuid: row.uuid},
                                    success: () => {
                                        dispatch(tableReduxReload({id: 'api_team_owner'}));
                                        setTimeout(() => {
                                            dispatch(snackbarReduxOpen({text: 'Membre supprimé.', variant: 'success'}))
                                        }, 500);
                                    },
                                    error: (response) => {
                                        dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                                    }
                                });
                            }
                        });
                    }}
                />}
                {!row.isAccepted && <ButtonIconComponent
                    background={color.colorWarningBackground}
                    backgroundHover={color.colorWarningBackground}
                    color={color.colorWarning}
                    icon={PersonRemove}
                    tooltip={'Annuler l\'invitation'}
                    onClick={() => {
                        Swal.fire({
                            title: 'Confirmation',
                            html: '<p>' +
                                'Êtes-vous certain de vouloir annuler l\'invitation de <br/>' + row.email + ' à rejoindre votre équipe ?' +
                                '</p>',
                            icon: 'warning',
                            showCancelButton: true,
                            color: color.textPrimary,
                            background: color.backgroundPrimary,
                            confirmButtonColor: color.colorConfirm,
                            cancelButtonColor: color.colorCancel,
                            confirmButtonText: 'Oui',
                            cancelButtonText: 'Non'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                routingApiHandlerFetch({
                                    route: 'api_team_owner_remove_user',
                                    method: 'POST',
                                    data: {uuid: row.uuid},
                                    success: () => {
                                        dispatch(tableReduxReload({id: 'api_team_owner'}));
                                        setTimeout(() => {
                                            dispatch(snackbarReduxOpen({text: 'Invitation annulée.', variant: 'success'}))
                                        }, 500);
                                    },
                                    error: (response) => {
                                        dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                                    }
                                });
                            }
                        });
                    }}
                />}
            </>
        );
    };

    // Tables Collaborative teams
    const columnsCollaborativeTeams = [
        {
            name: "avatar",
            label: " ",
            options: {filter: false, sort: false}
        },
        {
            name: "name",
            label: "Nom de l'équipe",
            options: {filter: true, sort: true}
        },
        {
            name: "actions",
            label: "Actions",
            options: {filter: false, sort: false}
        }
    ];
    const getActionCollaborativeTeams = (row) => {
        return (
            <>
                {row.isAccepted && <ButtonIconComponent
                    background={color.colorDeleteBackground}
                    backgroundHover={color.colorDeleteBackground}
                    color={color.colorDelete}
                    icon={Cancel}
                    tooltip={'Quitter l\'équipe'}
                    onClick={() => {
                        Swal.fire({
                            title: 'Confirmation',
                            html: '<p>' +
                                'Êtes-vous certain de vouloir vous retirer de l\'équipe ' + row.name + ' ?' +
                                '</p>',
                            icon: 'warning',
                            showCancelButton: true,
                            color: color.textPrimary,
                            background: color.backgroundPrimary,
                            confirmButtonColor: color.colorConfirm,
                            cancelButtonColor: color.colorCancel,
                            confirmButtonText: 'Oui',
                            cancelButtonText: 'Non'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                routingApiHandlerFetch({
                                    route: 'api_team_collaborative_leave',
                                    method: 'GET',
                                    params: {team: row.uuid},
                                    success: () => {
                                        dispatch(tableReduxReload({id: 'api_team_collaboratives'}));
                                        setTimeout(() => {
                                            dispatch(snackbarReduxOpen({text: 'Vous avez quitté l\'équipe.', variant: 'success'}))
                                        }, 500);
                                    },
                                    error: (response) => {
                                        dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                                    }
                                });
                            }
                        });
                    }}
                />}
                {!row.isAccepted &&
                    <Box sx={{width: '65px'}}>
                        <ButtonIconComponent
                            background={color.colorSuccessBackground}
                            backgroundHover={color.colorSuccessBackground}
                            color={color.colorSuccess}
                            icon={TaskAlt}
                            tooltip={'Rejoindre l\'équipe'}
                            onClick={() => {
                                routingApiHandlerFetch({
                                    route: 'api_team_collaborative_accept',
                                    method: 'GET',
                                    params: {team: row.uuid},
                                    success: () => {
                                        dispatch(tableReduxReload({id: 'api_team_collaboratives'}));
                                        setTimeout(() => {
                                            dispatch(snackbarReduxOpen({text: 'Vous faites désormais partie de l\'équipe.', variant: 'success'}))
                                        }, 500);
                                    },
                                    error: (response) => {
                                        dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                                    }
                                });
                            }}
                        />
                        <ButtonIconComponent
                            sx={{marginLeft: '5px'}}
                            background={color.colorDeleteBackground}
                            backgroundHover={color.colorDeleteBackground}
                            color={color.colorDelete}
                            icon={Cancel}
                            tooltip={'Refuser l\'invitation'}
                            onClick={() => {
                                Swal.fire({
                                    title: 'Confirmation',
                                    html: '<p>' +
                                        'Êtes-vous sûr de vouloir décliner l\'invitation à rejoindre l\'équipe ' + row.name + ' ?' +
                                        '</p>',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    color: color.textPrimary,
                                    background: color.backgroundPrimary,
                                    confirmButtonColor: color.colorConfirm,
                                    cancelButtonColor: color.colorCancel,
                                    confirmButtonText: 'Oui',
                                    cancelButtonText: 'Non'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        routingApiHandlerFetch({
                                            route: 'api_team_collaborative_leave',
                                            method: 'GET',
                                            params: {team: row.uuid},
                                            success: () => {
                                                dispatch(tableReduxReload({id: 'api_team_collaboratives'}));
                                                setTimeout(() => {
                                                    dispatch(snackbarReduxOpen({text: 'Vous avez quitté l\'équipe.', variant: 'success'}))
                                                }, 500);
                                            },
                                            error: (response) => {
                                                dispatch(snackbarReduxOpen({text: 'Oups ! Une erreur inattendue s\'est produite.', variant: 'error'}))
                                            }
                                        });
                                    }
                                });
                            }}
                        />
                    </Box>}
            </>
        );
    };

    React.useEffect(() => {
        dispatch(barMainLayoutReduxChange({
            title: 'Équipes',
            canBack: false
        }));

        routingApiHandlerFetch({
            route: 'api_team_owner',
            method: 'GET',
            success: (response) => {
                setTeamName(response.data.name);
                setTimeout(() => setLoading(false), 750);
            },
            error: () => {
                setTimeout(() => setLoading(false), 750);
            }
        });
    }, []);

    return (loading ? <Box sx={{height: 'calc(100vh - 120px)'}}><Loading/></Box> : <>
        <Fade in={true} {...{timeout: 500}}>
            <Box>
                <ShadowBoxComponent sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '15px'}}>
                    <Box sx={{position: 'relative'}}>
                        <img src="/images/teams.png" style={{width: '80px', marginRight: '15px'}}/>
                        {teamName && <Box sx={{position: 'absolute', bottom: '5px', right: '15px'}}>
                            <ButtonIconComponent
                                background={color.colorConfirmBackground}
                                backgroundHover={color.colorConfirmBackground}
                                color={color.colorConfirm}
                                icon={Settings}
                                onClick={() => {
                                    handlerTeam.setValue('name', teamName);
                                    setOpenDialogMyTeam(true);
                                }}
                            />
                        </Box>}
                    </Box>
                    <Box sx={{flex: 1}}>
                        <Typography sx={{fontSize: '18px', color: color.textPrimary, fontWeight: '400'}}>
                            L'innovation est faite pour être partagée
                        </Typography>
                        <Typography sx={{fontSize: '14px', color: color.textPrimary, fontWeight: '400'}}>
                            Collaborez efficacement et facilitez la gestion de vos solutions numériques et optimisez vos performances.
                        </Typography>
                        <Typography sx={{fontSize: '14px', color: color.textPrimary, fontWeight: '400'}}>
                            {teamName}
                        </Typography>
                        <Box sx={{maxWidth: '200px', marginTop: '5px'}}>
                            {!teamName && <ButtonComponent label={'Créer mon équipe'} onClick={() => setOpenDialogMyTeam(true)}/>}
                        </Box>
                    </Box>
                </ShadowBoxComponent>
            </Box>
        </Fade>
        <Fade in={true} {...{timeout: 500}}>
            <Grid container spacing={2}>
                {teamName && <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                    <TableComponent
                        id={'api_team_owner'}
                        title={'Mon équipe'}
                        height={hidden ? '' : 'calc(100vh - 350px)'}
                        columns={columnsMyTeam}
                        refreshInterval={5000}
                        icon={Groups}
                        promiseData={(resolve) => {
                            routingApiHandlerFetch({
                                route: 'api_team_owner',
                                method: 'GET',
                                success: (response) => {
                                    let data = [];
                                    for (let index in response.data.users) {
                                        let status = '';
                                        if (response.data.users[index].isOwner) status = 'Administrateur';
                                        else if (response.data.users[index].isAccepted) status = 'Membre';
                                        else if (!response.data.users[index].isAccepted) status = 'Invitation en attente';

                                        data.push({
                                            avatar: <Box sx={useStyles.avatar}>{response.data.users[index].firstname.charAt(0).toUpperCase() + response.data.users[index].lastname.charAt(0).toUpperCase()}</Box>,
                                            email: response.data.users[index].email,
                                            firstname: response.data.users[index].firstname,
                                            lastname: response.data.users[index].lastname,
                                            status: status,
                                            actions: getActionMyTeam(response.data.users[index])
                                        });
                                    }
                                    resolve(data);
                                },
                                error: () => {
                                    resolve([]);
                                }
                            });
                        }}
                        actionFirst={{
                            label: 'Invitez d\'autres personnes',
                            onClick: () => setOpenDialogAddUser(true)
                        }}
                    />
                </Grid>}

                <Grid item xs={12} sm={12} md={12} lg={teamName ? 4 : 12} xl={teamName ? 4 : 12}>
                    <TableComponent
                        id={'api_team_collaboratives'}
                        title={'Équipes collaboratives'}
                        height={hidden ? '' : 'calc(100vh - 355px)'}
                        columns={columnsCollaborativeTeams}
                        refreshInterval={5000}
                        icon={Groups3}
                        promiseData={(resolve) => {
                            routingApiHandlerFetch({
                                route: 'api_team_collaboratives',
                                method: 'GET',
                                success: (response) => {
                                    resolve(response.data);
                                    let data = [];
                                    for (let index in response.data) {
                                        data.push({
                                            name: response.data[index].name,
                                            actions: getActionCollaborativeTeams(response.data[index])
                                        });
                                    }
                                    resolve(data);
                                },
                                error: () => {
                                    resolve([]);
                                }
                            });
                        }}
                    />
                </Grid>
            </Grid>
        </Fade>

        <Dialog
            open={openDialogMyTeam}
            onClose={() => setOpenDialogMyTeam(false)}
            aria-labelledby="responsive-dialog-title"
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle id="responsive-dialog-title" sx={{background: color.backgroundPrimary, color: color.textPrimary}}>
                Mon équipe
            </DialogTitle>
            <DialogContent sx={{background: color.backgroundPrimary, paddingTop: '5px !important', padding: '5px 24px'}}>
                <TextFieldComponent handler={handlerTeam} id={'name'} onSubmit={saveTeam}/>
            </DialogContent>
            <DialogActions sx={{background: color.backgroundPrimary}}>
                <ButtonComponent disabled={loadingTeam} onClick={() => setOpenDialogMyTeam(false)} label={'Annuler'} color={color.textTertiary} colorHover={color.textTertiaryHover}/>
                <ButtonComponent onClick={saveTeam} label={'Enregistrer'} loading={loadingTeam}/>
            </DialogActions>
        </Dialog>

        <Dialog
            open={openDialogAddUser}
            onClose={() => setOpenDialogAddUser(false)}
            aria-labelledby="responsive-dialog-title"
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogTitle id="responsive-dialog-title" sx={{background: color.backgroundPrimary, color: color.textPrimary}}>
                Ajouter un membre à mon équipe
            </DialogTitle>
            <DialogContent sx={{background: color.backgroundPrimary, paddingTop: '5px !important', padding: '5px 24px'}}>
                <TextFieldComponent handler={handlerAddUser} id={'email'} onSubmit={saveAddUser}/>
            </DialogContent>
            <DialogActions sx={{background: color.backgroundPrimary}}>
                <ButtonComponent disabled={loadingTeam} onClick={() => setOpenDialogAddUser(false)} label={'Annuler'} color={color.textTertiary} colorHover={color.textTertiaryHover}/>
                <ButtonComponent onClick={saveAddUser} label={'Enregistrer'} loading={loadingTeam}/>
            </DialogActions>
        </Dialog>
    </>);
}

export default Teams;

export const color = {
    primary: '#6F61F0FF',
    primaryOpacity: 'rgba(115,102,240,0.2)',
    primaryOpacityMore: 'rgba(115,102,240,0.05)',
    primaryHover: '#6358d0',
    secondary: '#DB1EE8',
    secondaryOpacity: 'rgba(219,30,232,0.2)',
    secondaryOpacityMore: 'rgba(219,30,232,0.05)',
    secondaryHover: 'rgba(219,30,232,0.80)',

    backgroundPrimary: '#303349',
    backgroundPrimaryOpacity: 'rgba(48,51,73,0.2)',
    backgroundPrimaryMoreOpacity: 'rgba(115,102,240,0.05)',
    backgroundSecondary: '#26293D',

    textPrimary: '#B6BFE3',
    textPrimaryActive: '#ffffff',
    textSecondary: '#9CA1BB',
    textTertiary: '#676a80',
    textTertiaryHover: '#555869',
    textError: '#EA5455',
    textDisabled: 'rgba(165,168,182,0.5)',

    tableHead: '#3F3C6A',

    colorEdit: '#07D0E7',
    colorEditBackground: 'rgba(7,208,231,0.05)',
    colorEditBackgroundHover: 'rgba(7,208,231,0.15)',
    colorDelete: '#EA5455',
    colorDeleteBackground: 'rgba(234,84,85,0.05)',
    colorDeleteBackgroundHover: 'rgba(234,84,85,0.15)',
    colorCancel: '#EA5455',
    colorCancelBackground: 'rgba(234,84,85,0.05)',
    colorCancelBackgroundHover: 'rgba(234,84,85,0.15)',
    colorDownload: '#6F61F0FF',
    colorDownloadBackground: 'rgba(111,97,240,0.05)',
    colorDownloadBackgroundHover: 'rgba(111,97,240,0.15)',
    colorConfirm: '#6F61F0FF',
    colorConfirmBackground: 'rgba(111,97,240,0.05)',
    colorConfirmBackgroundHover: 'rgba(111,97,240,0.15)',
    colorSecondConfirm: '#DB1EE8',
    colorSecondConfirmBackground: 'rgba(219,30,232,0.05)',
    colorSecondConfirmBackgroundHover: 'rgba(219,30,232,0.15)',
    colorRestore: '#49934B',
    colorRestoreBackground: 'rgba(73,147,75,0.05)',
    colorRestoreBackgroundHover: 'rgba(73,147,75,0.15)',

    colorSuccess: '#49934B',
    colorSuccessHover: '#3a733c',
    colorSuccessBackground: 'rgba(73,147,75,0.15)',
    colorInfo: '#17a2b8',
    colorInfoBackground: 'rgba(23,162,184,0.15)',
    colorWarning: '#FFC107',
    colorWarningBackground: 'rgba(255,193,7,0.15)',
    colorError: '#EA5455',
    colorErrorBackground: 'rgba(234,84,85,0.15)',
};


import React, {useEffect} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {Box} from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import {styled, useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import TextFieldComponent from "src/Component/TextFieldComponent";
import ButtonComponent from "src/Component/ButtonComponent";
import FooterIllustration from "./Component/FooterIllustration";
import {Alert, Fade, Grid} from "@mui/material";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import {formHandlerInit} from "src/Handler/FormHandler";
import {color} from "src/Config/Theme";
import SelectComponent from "src/Component/SelectComponent";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import Loading from "../Loading/Loading";
import {ContentCopy, DoneAll, EventAvailable, HourglassDisabled, HourglassTop, VisibilityOff} from "@mui/icons-material";
import ButtonIconComponent from "src/Component/ButtonIconComponent";
import {snackbarReduxOpen} from "../../Redux/snackbarRedux";
import {useDispatch} from "react-redux";

// ** Styled Components
const SecretIllustration = styled('img')(({theme}) => ({
    zIndex: 2,
    maxHeight: 620,
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down(1540)]: {
        maxHeight: 550
    },
    [theme.breakpoints.down('lg')]: {
        maxHeight: 500
    }
}))
const RightWrapper = styled(Box)(({theme}) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
        maxWidth: 450
    },
    [theme.breakpoints.up('lg')]: {
        maxWidth: 600
    },
    [theme.breakpoints.up('xl')]: {
        maxWidth: 750
    }
}));
const LinkStyled = styled(Link)(() => ({
    fontSize: '15px',
    textDecoration: 'none',
    color: '#7366F0'
}));

function Secret() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const hidden = useMediaQuery(theme.breakpoints.down('md'));
    const {secretParams} = useParams();

    const [loading, setLoading] = React.useState(false);
    const [loadingGlobal, setLoadingGlobal] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [randIllustration] = React.useState(Math.random() < 0.5);
    const [isCheck, setIsCheck] = React.useState(false);

    const [message, setMessage] = React.useState(null);
    const [secret, setSecret] = React.useState(null);
    const [secrets, setSecrets] = React.useState(null);
    const [createdAt, setCreatedAt] = React.useState(null);
    const [expireAt, setExpireAt] = React.useState(null);

    // Form
    const [form, setForm] = React.useState({
        message: {
            id: 'message',
            label: 'Votre message',
            helperText: 'Le contenu de votre secret va ici...',
            type: 'text', value: '',
            options: {validation: ['required'], max: 250}
        },
        password: {
            id: 'password',
            label: 'Mot de passe',
            helperText: 'Entrez un mot de passe.',
            type: 'password', value: '',
            options: {validation: []}
        },
        expireAt: {
            id: 'expireAt',
            label: 'Expiration',
            helperText: 'Choisissez le délai d\'expiration.',
            type: 'text', value: '7d',
            options: {validation: ['required']}
        },
        count: {
            id: 'count',
            label: 'Nombre de lien',
            helperText: 'le nombre de liens à générer.',
            type: 'text', value: 1,
            options: {validation: ['required']}
        }
    });
    const handler = formHandlerInit(form, setForm);
    const [optionExpireAts] = React.useState([
        {label: 'Expire dans 5 minutes', value: '5m'},
        {label: 'Expire dans 30 minutes', value: '30m'},
        {label: 'Expire dans 1 heure', value: '1h'},
        {label: 'Expire dans 4 heures', value: '4h'},
        {label: 'Expire dans 12 heures', value: '12h'},
        {label: 'Expire dans 1 jour', value: '1d'},
        {label: 'Expire dans 3 jours', value: '3d'},
        {label: 'Expire dans 7 jours', value: '7d'}
    ]);
    const [optionCounts] = React.useState([
        {label: '1 lien', value: 1},
        {label: '2 liens', value: 2},
        {label: '3 liens', value: 3},
        {label: '4 liens', value: 4},
        {label: '5 liens', value: 5},
    ]);

    const [formPassword, setFormPassword] = React.useState({
        password: {
            id: 'password',
            label: 'Mot de passe',
            helperText: 'Entrez le mot de passe.',
            type: 'password', value: '',
            options: {validation: ['required']}
        }
    });
    const handlerPassword = formHandlerInit(formPassword, setFormPassword);

    const save = () => {
        if (handler.checkValid() < 1 && isCheck) {
            setIsError(false);
            handler.setLoading(true);
            setLoading(true);

            routingApiHandlerFetch({
                route: 'api_free_tools_secret',
                method: 'POST',
                params: {secret: ''},
                data: handler.getDataApi(),
                success: (response) => {
                    handler.setLoading(false);
                    setLoading(false);
                    setIsCheck(false);
                    handler.reset();
                    handler.setValue('expireAt', '7d');
                    handler.setValue('count', 1);
                    navigate(routingHandlerGetRoutePathname('free_tools_secret', {secretParams: response.data.uuid}));
                }, error: (response) => {
                    if (response.code === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        setIsError(true);
                    }

                    handler.setLoading(false);
                    setLoading(false);
                }
            });
        }
    };
    const reveal = (secret) => {
        if (!secret.hasPassword || handlerPassword.checkValid() < 1) {
            handlerPassword.setLoading(true);
            setLoading(true);

            routingApiHandlerFetch({
                route: 'api_free_tools_secret_reveal',
                method: 'POST',
                params: {secret: secretParams},
                data: {password: handlerPassword.getValue('password')},
                success: (response) => {
                    handlerPassword.setLoading(false);
                    setLoading(false);
                    setMessage(response.data.message);
                    secret.isRead = true;
                    setSecret({...secret});
                }, error: (response) => {
                    if (response.code === 400) {
                        handlerPassword.setErrorApi(response.error);
                    } else {
                        setIsError(true);
                    }

                    handlerPassword.setLoading(false);
                    setLoading(false);
                }
            });
        }
    };

    useEffect(() => {
        document.title = "Partage sécurisé de messages et mots de passe - Simple et Confidentiel";
        document.querySelector('meta[name="description"]')?.setAttribute(
            "content",
            "Protégez vos informations sensibles grâce à notre plateforme de partage sécurisé. Messages, mots de passe et liens privés : un partage rapide, confidentiel et respectueux de votre vie privée."
        );

        let meta = document.querySelector('meta[name="keywords"]');
        let createdMeta = false;

        if (meta) {
            meta.setAttribute("content", "Partage sécurisé, mot de passe, confidentiel");
        } else {
            meta = document.createElement("meta");
            meta.name = "keywords";
            meta.content = "Partage sécurisé, mot de passe, confidentiel";
            document.head.appendChild(meta);
            createdMeta = true;
        }

        return () => {
            if (createdMeta) {
                document.head.removeChild(meta);
            }
        };
    }, []);
    useEffect(() => {
        if (secretParams) {
            setLoadingGlobal(true);
            const callInfo = () => {
                routingApiHandlerFetch({
                    route: 'api_free_tools_secret',
                    method: 'GET',
                    params: {secret: secretParams},
                    data: {},
                    success: (response) => {
                        setSecret(response.data.secret);
                        setSecrets(response.data.secrets);
                        if (response.data.secrets) {
                            setCreatedAt(response.data.secrets[0].createdAt);
                            setExpireAt(response.data.secrets[0].expireAt);
                        }
                        setLoadingGlobal(false);
                    }, error: (response) => {
                        setIsError(true);
                        setLoadingGlobal(false);
                    }
                });
            };
            const loopInterval = setInterval(callInfo, 1000 * 5);
            callInfo();

            return () => {
                clearInterval(loopInterval);
            };
        }
    }, [secretParams]);

    return (
        <>
            {loadingGlobal ? <Loading/> :
                <Box sx={{
                    display: 'flex',
                    minHeight: '100vh',
                    position: 'relative'
                }}>
                    {!hidden ? (<Fade in={true} {...{timeout: 500}}>
                        <Box sx={{
                            flex: 1,
                            display: 'flex',
                            position: 'relative',
                            alignItems: 'center',
                            borderRadius: '20px',
                            justifyContent: 'center',
                            backgroundColor: '#26293D',
                            margin: '2rem 0rem 2rem 2rem',
                        }}>
                            <SecretIllustration alt='forgot-password-illustration' src={'/images/' + (randIllustration ? 'illustration-boy-forgot-password-dark.webp' : 'illustration-girl-forgot-password-dark.webp')}/>
                            <FooterIllustration/>
                        </Box>
                    </Fade>) : null
                    }
                    <Fade in={true} {...{timeout: 500}}>
                        <RightWrapper>
                            <Box
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                {!secretParams && <Box sx={{padding: '15px 25px', width: '100%', maxWidth: 560, overflow: 'scroll', maxHeight: 'calc(100vh - 30px)'}}>
                                    <LinkStyled to={'https://maker-system.com/'}>
                                        <img width={34} height={34} src={'/images/logo.webp'} alt={'logo'}/>
                                    </LinkStyled>

                                    <Box sx={{margin: '15px 0'}}>
                                        <Typography sx={{mb: 1.5, fontWeight: 500, fontSize: '1.625rem', lineHeight: 1.385, color: color.textPrimary}}>
                                            {`Partagez vos mots de passe, messages secrets ou liens privés en toute sécurité 🔒`}
                                        </Typography>
                                        <Typography sx={{fontSize: '12px', color: color.textPrimary, fontWeight: '100 !important'}}>
                                            Protégez vos informations sensibles en les tenant à l’écart de vos journaux de messagerie et de discussion.
                                            Votre message ne pourra être lu qu'une seule fois avant de disparaître pour toujours. <br/><br/>
                                            Simple, rapide et entièrement confidentiel.
                                        </Typography>
                                    </Box>

                                    <form noValidate autoComplete='current-password'>
                                        <Box sx={{padding: '5px 0'}}>
                                            <TextFieldComponent handler={handler} id={'message'} multiline={true}/>
                                        </Box>

                                        <Box sx={{padding: '5px 0'}}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                    <SelectComponent handler={handler} id={'expireAt'} options={optionExpireAts}/>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                                    <SelectComponent handler={handler} id={'count'} options={optionCounts}/>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Box sx={{padding: '5px 0'}}>
                                            <TextFieldComponent handler={handler} id={'password'} autoComplete={'off'}/>
                                        </Box>
                                        <Box sx={{padding: '5px 0'}}>

                                            <div className="checkbox-container" style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                                                <div className="checkbox-wrapper-12">
                                                    <div className="cbx">
                                                        <input
                                                            type="checkbox"
                                                            id="cbx-12"
                                                            checked={isCheck}
                                                            onChange={() => setIsCheck(!isCheck)}
                                                        />
                                                        <label htmlFor="cbx-12"></label>
                                                        <svg fill="none" viewBox="0 0 15 14" height="14" width="15">
                                                            <path d="M2 8.36364L6.23077 12L13 2"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <Typography sx={{fontSize: '12px', color: color.textPrimary, fontWeight: '100 !important', cursor: 'pointer'}} onClick={() => setIsCheck(!isCheck)}>
                                                    En cochant cette case, je déclare accepter les <a href="https://maker-system.com/cgu/" target={'_blank'} style={{color: color.textPrimary, textDecoration: 'underline'}}>Conditions d'utilisation</a> et la <a href="https://maker-system.com/politique-de-confidentialite/" target={'_blank'} style={{color: color.textPrimary, textDecoration: 'underline'}}>Politique de confidentialité</a>.
                                                    Je confirme que le contenu partagé respecte la législation en vigueur et n'engage pas la responsabilité de l'entreprise en cas de mauvaise utilisation.
                                                </Typography>
                                            </div>
                                        </Box>

                                        <Box sx={{textAlign: 'center', width: '100%', marginBottom: '25px', marginTop: '5px'}}>
                                            <ButtonComponent label={'Générer les liens'} disabled={!isCheck} onClick={save} loading={loading}/>
                                        </Box>
                                        {isError && <><Alert sx={{opacity: 0.7}} severity="error">Oups ! Une erreur inattendue s'est produite.</Alert><br/></>}
                                    </form>

                                </Box>}
                                {(secretParams && secrets) && <Box sx={{padding: '15px 25px', width: '100%', maxWidth: 560, overflow: 'scroll', maxHeight: 'calc(100vh - 30px)'}}>
                                    <LinkStyled to={'https://maker-system.com/'}>
                                        <img width={34} height={34} src={'/images/logo.webp'} alt={'logo'}/>
                                    </LinkStyled>

                                    <Box sx={{margin: '15px 0'}}>
                                        <Typography sx={{mb: 1.5, fontWeight: 500, fontSize: '1.625rem', lineHeight: 1.385, color: color.textPrimary}}>
                                            {`Partagez vos mots de passe, messages secrets ou liens privés en toute sécurité 🔒`}
                                        </Typography>
                                        <Typography sx={{fontSize: '12px', color: color.textPrimary, fontWeight: '100 !important'}}>
                                            Votre secret est prêt à être partagé.<br/>
                                            Vous pouvez transmettre les liens ci-dessous à vos destinataires.<br/>
                                            Ces liens resteront accessibles depuis cette page pour consultation.<br/><br/>
                                            💡 Astuce : Ajoutez cette URL à vos favoris pour suivre vos partages de secrets en toute simplicité.<br/>
                                            ⚠️ Chaque lien ne pourra être consulté qu'une seule fois avant d'être définitivement supprimé.
                                        </Typography>
                                    </Box>

                                    <Box sx={{padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <EventAvailable sx={{color: color.textPrimary, marginRight: '5px'}}/>
                                        <Typography sx={{fontSize: '12px', color: color.textPrimary, flex: 1, whiteSpace: 'wrap'}}>
                                            Créé le {createdAt}
                                        </Typography>
                                        <HourglassTop sx={{color: color.textPrimary, marginRight: '5px'}}/>
                                        <Typography sx={{fontSize: '12px', color: color.textPrimary, flex: 1, whiteSpace: 'wrap'}}>
                                            Expire le {expireAt}
                                        </Typography>
                                    </Box>

                                    {secrets.map((secret, index) => (
                                        <Box key={index} sx={{margin: '15px 0', background: color.backgroundSecondary, borderRadius: '10px', overflow: 'hidden'}}>
                                            <Box sx={{height: '4px', width: '100%', background: secret.isRead ? color.colorConfirm : (!secret.isExpired ? color.colorSuccess : color.colorWarning)}}/>

                                            <Box sx={{padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                                <Typography sx={{fontSize: '12px', color: color.textPrimary, flex: 1, whiteSpace: 'wrap'}}>
                                                    <div
                                                        onDoubleClick={(e) => {
                                                            const range = document.createRange();
                                                            range.selectNodeContents(e.currentTarget);
                                                            const selection = window.getSelection();
                                                            selection.removeAllRanges();
                                                            selection.addRange(range);
                                                        }}
                                                        contentEditable={true}
                                                        suppressContentEditableWarning={false}
                                                        onKeyDown={(e) => {
                                                            if (!((e.ctrlKey || e.metaKey) && (e.key === "a" || e.key === "c"))) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onContextMenu={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                        style={{
                                                            whiteSpace: "pre-wrap",
                                                            cursor: "text",
                                                            outline: "none",
                                                            userSelect: "text",
                                                        }}>{window.location.protocol + '//' + window.location.host + '/free-tools/secret/' + secret.uuid}</div>
                                                </Typography>
                                                {(!secret.isExpired && !secret.isRead) && <ButtonIconComponent
                                                    background={color.colorSuccessBackground}
                                                    backgroundHover={color.colorSuccessBackground}
                                                    color={color.colorSuccess}
                                                    icon={ContentCopy}
                                                    tooltip={'Copier le lien'}
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(window.location.protocol + '//' + window.location.host + '/free-tools/secret/' + secret.uuid)
                                                            .then(() => {
                                                                dispatch(snackbarReduxOpen({text: 'Lien copié avec succès !', variant: 'success'}));
                                                            })
                                                            .catch((err) => {
                                                                dispatch(snackbarReduxOpen({text: 'Échec de la copie dans le presse-papiers !', variant: 'error'}));
                                                            });
                                                    }}
                                                />}
                                                {
                                                    (secret.isExpired && !secret.isRead) && <ButtonIconComponent
                                                        background={color.colorWarningBackground}
                                                        backgroundHover={color.colorWarningBackground}
                                                        color={color.colorWarning}
                                                        icon={HourglassDisabled}
                                                        tooltip={'Lien expiré'}
                                                    />
                                                }
                                                {
                                                    (secret.isRead) && <ButtonIconComponent
                                                        background={color.colorConfirmBackgroundHover}
                                                        backgroundHover={color.colorConfirmBackgroundHover}
                                                        color={color.colorConfirm}
                                                        icon={DoneAll}
                                                        tooltip={'Lien distribué'}
                                                    />
                                                }
                                            </Box>
                                        </Box>
                                    ))}

                                    <Box sx={{textAlign: 'center', width: '100%', marginBottom: '25px', marginTop: '5px'}}>
                                        <ButtonComponent label={'Créer un nouveau secret'} onClick={() => navigate(routingHandlerGetRoutePathname('free_tools_secret', {secretParams: null}))} loading={loading}/>
                                    </Box>
                                </Box>}
                                {(secretParams && secret) && <Box sx={{padding: '15px 25px', width: '100%', maxWidth: 560, overflow: 'scroll', maxHeight: 'calc(100vh - 30px)'}}>
                                    <LinkStyled to={'https://maker-system.com/'}>
                                        <img width={34} height={34} src={'/images/logo.webp'} alt={'logo'}/>
                                    </LinkStyled>

                                    <Box sx={{margin: '15px 0'}}>
                                        <Typography sx={{mb: 1.5, fontWeight: 500, fontSize: '1.625rem', lineHeight: 1.385, color: color.textPrimary}}>
                                            {`Partagez vos mots de passe, messages secrets ou liens privés en toute sécurité 🔒`}
                                        </Typography>
                                        <Typography sx={{fontSize: '12px', color: color.textPrimary, fontWeight: '100 !important'}}>
                                            Ce message vous a été transmis de manière confidentielle. <br/><br/>
                                            ⚠️ Veuillez noter que le lien est à usage unique et disparaîtra après sa consultation.<br/>
                                        </Typography>
                                    </Box>

                                    <Box sx={{padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        <EventAvailable sx={{color: color.textPrimary, marginRight: '5px'}}/>
                                        <Typography sx={{fontSize: '12px', color: color.textPrimary, flex: 1, whiteSpace: 'wrap'}}>
                                            Créé le {secret.createdAt}
                                        </Typography>
                                        <HourglassTop sx={{color: color.textPrimary, marginRight: '5px'}}/>
                                        <Typography sx={{fontSize: '12px', color: color.textPrimary, flex: 1, whiteSpace: 'wrap'}}>
                                            Expire le {secret.expireAt}
                                        </Typography>
                                    </Box>

                                    <Box sx={{margin: '15px 0', background: color.backgroundSecondary, borderRadius: '10px', overflow: 'hidden'}}>
                                        <Box sx={{height: '4px', width: '100%', background: secret.isRead ? color.colorConfirm : (!secret.isExpired ? color.colorSuccess : color.colorWarning)}}/>

                                        <Box sx={{padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <Typography sx={{fontSize: '15px', color: color.textPrimary, flex: 1, whiteSpace: 'wrap'}}>
                                                {message ? <div
                                                        contentEditable={true}
                                                        suppressContentEditableWarning={false}
                                                        onKeyDown={(e) => {
                                                            if (!((e.ctrlKey || e.metaKey) && (e.key === "a" || e.key === "c"))) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onContextMenu={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                        style={{
                                                            whiteSpace: "pre-wrap",
                                                            cursor: "text",
                                                            outline: "none",
                                                            userSelect: "text", // Permet la sélection
                                                        }}
                                                        dangerouslySetInnerHTML={{__html: message.replace(/\r?\n/g, '<br />')}}/> :
                                                    '******************************'}
                                            </Typography>
                                            {(!secret.isExpired && !secret.isRead) && <ButtonIconComponent
                                                background={color.colorSuccessBackground}
                                                backgroundHover={color.colorSuccessBackground}
                                                color={color.colorSuccess}
                                                icon={VisibilityOff}
                                                tooltip={'Message caché'}
                                            />}
                                            {
                                                (secret.isExpired && !secret.isRead) && <ButtonIconComponent
                                                    background={color.colorWarningBackground}
                                                    backgroundHover={color.colorWarningBackground}
                                                    color={color.colorWarning}
                                                    icon={HourglassDisabled}
                                                    tooltip={'Lien expiré'}
                                                />
                                            }
                                            {
                                                (!message && secret.isRead) && <ButtonIconComponent
                                                    background={color.colorConfirmBackgroundHover}
                                                    backgroundHover={color.colorConfirmBackgroundHover}
                                                    color={color.colorConfirm}
                                                    icon={DoneAll}
                                                    tooltip={'Message déjà lu'}
                                                />
                                            }
                                            {(message && secret.isRead) && <ButtonIconComponent
                                                background={color.colorConfirmBackgroundHover}
                                                backgroundHover={color.colorConfirmBackgroundHover}
                                                color={color.colorConfirm}
                                                icon={ContentCopy}
                                                tooltip={'Copier le message'}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(message)
                                                        .then(() => {
                                                            dispatch(snackbarReduxOpen({text: 'Lien copié avec succès !', variant: 'success'}));
                                                        })
                                                        .catch((err) => {
                                                            dispatch(snackbarReduxOpen({text: 'Échec de la copie dans le presse-papiers !', variant: 'error'}));
                                                        });
                                                }}
                                            />}
                                        </Box>
                                    </Box>

                                    <Box sx={{textAlign: 'end', padding: '0 10px 10px'}}>
                                        {(secret.isExpired && !secret.isRead) && <Typography sx={{fontSize: '11px', color: color.colorWarning, marginTop: '-15px'}}>
                                            Oups, trop tard le message est expiré.
                                        </Typography>}
                                        {(!secret.isExpired && !secret.isRead) && <Typography sx={{fontSize: '11px', color: color.colorSuccess, marginTop: '-15px'}}>
                                            Révélez le contenu et récupérez le secret qui vous a été partagé.
                                        </Typography>}
                                        {(!message && secret.isRead) && <Typography sx={{fontSize: '11px', color: color.colorConfirm, marginTop: '-15px'}}>
                                            Ce message a déjà été lu et n'est plus accessible.
                                        </Typography>}
                                        {(message && secret.isRead) && <Typography sx={{fontSize: '12px', color: color.colorConfirm, marginTop: '-15px'}}>
                                            Le message a été révélé et sera définitivement supprimé après sa lecture.
                                        </Typography>}
                                    </Box>

                                    {(secret.hasPassword && (!secret.isExpired && !secret.isRead)) && <Box sx={{padding: '5px 0'}}>
                                        <TextFieldComponent handler={handlerPassword} id={'password'} autoComplete={'off'}/>
                                    </Box>}
                                    {(!secret.isExpired && !secret.isRead) && <Box sx={{textAlign: 'center', width: '100%', marginBottom: '10px', marginTop: '5px'}}>
                                        <ButtonComponent color={color.colorSuccess} colorHover={color.colorSuccessHover} label={'Réléver le message'} onClick={() => reveal(secret)} loading={loading}/>
                                    </Box>}
                                    {(!(!secret.isExpired && !secret.isRead) && message) && <Box sx={{textAlign: 'center', width: '100%', marginBottom: '10px', marginTop: '5px'}}>
                                        <ButtonComponent color={color.colorSuccess} label={'Réléver le message'} disabled={true}/>
                                    </Box>}

                                    <Box sx={{textAlign: 'center', width: '100%'}}>
                                        <ButtonComponent label={'Créer un nouveau secret'} onClick={() => navigate(routingHandlerGetRoutePathname('free_tools_secret', {secretParams: null}))} loading={loading}/>
                                    </Box>
                                </Box>}
                            </Box>
                        </RightWrapper>
                    </Fade>
                </Box>
            }
        </>
    );
}

export default Secret;

import React from 'react';
import {Link} from "react-router-dom";
import {Box} from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import {styled, useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {routingHandlerGetRoutePathname} from "src/Handler/RoutingHandler";
import TextFieldComponent from "src/Component/TextFieldComponent";
import ButtonComponent from "src/Component/ButtonComponent";
import FooterIllustration from "./Component/FooterIllustration";
import {Alert, Fade} from "@mui/material";
import {routingApiHandlerFetch} from "src/Handler/RoutingApiHandler";
import {formHandlerInit} from "src/Handler/FormHandler";
import {color} from "../../Config/Theme";
import {ArrowBackIos} from '@mui/icons-material';

// ** Styled Components
const ForgotPasswordIllustration = styled('img')(({theme}) => ({
    zIndex: 2,
    maxHeight: 620,
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down(1540)]: {
        maxHeight: 550
    },
    [theme.breakpoints.down('lg')]: {
        maxHeight: 500
    }
}))
const RightWrapper = styled(Box)(({theme}) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
        maxWidth: 450
    },
    [theme.breakpoints.up('lg')]: {
        maxWidth: 600
    },
    [theme.breakpoints.up('xl')]: {
        maxWidth: 750
    }
}));
const LinkStyled = styled(Link)(() => ({
    fontSize: '15px',
    textDecoration: 'none',
    color: '#7366F0'
}));

function ForgotPassword() {
    const theme = useTheme();
    const hidden = useMediaQuery(theme.breakpoints.down('md'));
    const [isError, setIsError] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [randIllustration] = React.useState(Math.random() < 0.5);

    // Form
    const [form, setForm] = React.useState({
        email: {
            id: 'email',
            label: 'Email',
            helperText: 'Entrez votre email.',
            type: 'text',
            value: '',
            options: {validation: ['required', 'email']}
        }
    });
    const handler = formHandlerInit(form, setForm);

    const forgotPassword = () => {
        if (handler.checkValid() < 1) {
            handler.setLoading(true);
            setIsError(false);
            setIsSuccess(false);
            setLoading(true);

            routingApiHandlerFetch({
                route: 'api_forgot_password',
                method: 'POST',
                data: {email: form.email.value},
                success: () => {
                    handler.reset();
                    setIsSuccess(true);
                    handler.setLoading(false);
                    setLoading(false);
                },
                error: (response) => {
                    if (response.code === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        setIsError(true);
                    }

                    handler.setLoading(false);
                    setLoading(false);
                }
            });
        }

    };

    return (
        <Box sx={{
            display: 'flex',
            minHeight: '100vh',
            overflowX: 'hidden',
            position: 'relative'
        }}>
            {!hidden ? (
                <Fade in={true} {...{timeout: 500}}>
                    <Box sx={{
                        flex: 1,
                        display: 'flex',
                        position: 'relative',
                        alignItems: 'center',
                        borderRadius: '20px',
                        justifyContent: 'center',
                        backgroundColor: '#26293D',
                        margin: '2rem 0rem 2rem 2rem'
                    }}>
                        <ForgotPasswordIllustration alt='forgot-password-illustration' src={'/images/' + (randIllustration ? 'illustration-boy-forgot-password-dark.webp' : 'illustration-girl-forgot-password-dark.webp')}/>
                        <FooterIllustration/>
                    </Box>
                </Fade>
            ) : null}
            <Fade in={true} {...{timeout: 500}}>
                <RightWrapper>
                    <Box
                        sx={{
                            padding: '0 25px',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Box sx={{width: '100%', maxWidth: 400}}>

                            <LinkStyled to={'https://maker-system.com/'}>
                                <img width={34} height={34} src={'/images/logo.webp'} alt={'logo'}/>
                            </LinkStyled>

                            <Box sx={{margin: '15px 0'}}>
                                <Typography sx={{mb: 1.5, fontWeight: 500, fontSize: '1.625rem', lineHeight: 1.385, color: color.textPrimary}}>
                                    {`Mot de passe oublié ? 🔒`}
                                </Typography>
                                <Typography sx={{color: color.textPrimary, fontWeight: '100 !important'}}>
                                    Entrez votre email et nous vous enverrons des instructions pour réinitialiser votre mot de passe
                                </Typography>
                            </Box>

                            {isError && <><Alert sx={{opacity: 0.7}} severity="error">Oups ! Une erreur inattendue s'est produite.</Alert><br/></>}
                            {isSuccess && <><Alert sx={{opacity: 0.7}} severity="success">Si votre adresse email existe dans notre base de données, vous recevrez un lien de récupération de mot de passe à votre adresse email dans quelques minutes.</Alert><br/></>}

                            <form noValidate autoComplete='current-password'>
                                <Box sx={{padding: '5px 0'}}>
                                    <TextFieldComponent handler={handler} id={'email'} onSubmit={forgotPassword}/>
                                </Box>

                                <Box sx={{textAlign: 'center', width: '100%', marginBottom: '25px'}}>
                                    <ButtonComponent label={'Envoyer le lien de réinitialisation'} onClick={forgotPassword} loading={loading}/>
                                </Box>

                                <Box sx={{textAlign: 'center', width: '100%'}}>
                                    <LinkStyled to={routingHandlerGetRoutePathname('login')} sx={{fontSize: '15px'}}>
                                        <ArrowBackIos sx={{fontSize: 17, color: color.primary, verticalAlign: 'bottom'}}/> Retour à la connexion
                                    </LinkStyled>
                                </Box>
                            </form>

                        </Box>
                    </Box>
                </RightWrapper>
            </Fade>
        </Box>
    );
}

export default ForgotPassword;
